import usSectors from "../../../public/us/sector.json"
import usIndustries from "../../../public/us/industry.json"
import usCompanies from "../../../public/us/company.json"
import inSectors from "../../../public/in/sector.json"
import inIndustries from "../../../public/in/industry.json"
import inCompanies from "../../../public/in/company.json"
import config from "@/client/constants/config.json"
import {
	AiAssistQuestion,
	FilingConfiguration,
	FocusItems,
} from "@/client/lib/interface"

const FILING_CONFIG: FilingConfiguration = config.filing_config
import getAnnouncementTypes, { getYears } from "@/client/lib/helpers"
import { defaultAnnouncementTypeMap } from "@/config/country.config"
import { PricingContentProp } from "@/client/lib/interface"
import { CountryCode } from "@/common/config"

type DocTypeProp = {
	id: string
	title: string
	filter_key: string
}[]

export type ICountry = "us" | "in"

const ROUTES = {
	HOME: "/",
	AUTH: "/auth",
	SIGN_OUT: "/auth/signout",
	PRODUCT: "/product",
	DASHBOARD: (country: CountryCode) => `/${country.toLowerCase()}/ai-assist`,
	PRICING: "/pricing",
	BLOG: "https://blog.stockinsights.ai/blog",
	REFERRAL: "/referral",
	CONTACT_US: "/contact-us",
	TWITTER: "https://twitter.com/stockinsightsai",
	LINKEDIN: "https://www.linkedin.com/company/stockinsights-ai",
	ABOUT_US:
		"https://stockinsights.notion.site/StockInsights-1bcb6aba7f19475c85f737dbf037ee97",
	CARRERS: "/carrer",
	CASE_STUDIES: "/case-studies",
	GLOSSARY: "/glossary",
	AFFILATES: "/affilates",
	API_DOC: "https://docs.stockinsights.ai/api-reference",
	PRIVACY_POLICY: "/privacy-policy",
	TERMS_AND_CONDITIONS: "/terms",
	REFUND_POLICY: "/refund",
	SUPPORT: "/support",
	ERROR: "/error",
	SIGN_IN: "/auth?view=sign_in",
	SIGN_UP: "/auth?view=sign_up",
	AI_ASSIST: "/ai-assist",
	ONBOARDING: "onboarding",
	USER_STORIES: "https://docs.stockinsights.ai/introduction",
}

const DOC_TYPES: DocTypeProp = [
	{
		id: "EC",
		title: "Earnings Transcripts",
		filter_key: "earnings-transcript",
	},
	{
		id: "AN",
		title: "Announcement",
		filter_key: "announcement",
	},
	{
		id: "AR",
		title: "Annual Report",
		filter_key: "annual-report",
	},
	{
		id: "IP",
		title: "Investor Presentation",
		filter_key: "investor-presentation",
	},
]

export type SideBarItem = {
	title: string
	filter_key: "sentiment" | "announcement_type_id" | "filing_8k_type_id"
	options: ItemProp[]
	hasAttribute: boolean
}

const SENTIMENTS: SideBarItem = {
	title: "Sentiment",
	filter_key: "sentiment",
	options: ["Positive", "Neutral", "Negative"].map(item => ({
		id: item.toLocaleLowerCase(),
		name: item,
	})),
	hasAttribute: true,
}

export interface AnnouncementTypeMap {
	[key: string]: {
		[key: string]: string[]
	}
}

const announcementTypes = getAnnouncementTypes(defaultAnnouncementTypeMap)

const ANNOUNCEMENTS_CATEGORIES: SideBarItem = {
	title: "Category",
	filter_key: "announcement_type_id",
	options: announcementTypes,
	hasAttribute: true,
}

const SEARCH_YEARS = getYears(2022).reverse()
const SEARCH_QUARTERS = ["Q4", "Q3", "Q2", "Q1"]

export type ItemProp = {
	id: string
	name: string
	count?: number
}

export type FilterType =
	| "sectors"
	| "industries"
	| "company_id"
	| "years"
	| "quarters"
	| "marketcap_category"

export type FilterProps = {
	[key in string]: ItemProp[]
}

export type FilterMenuOption = {
	key: string
	title: string
	searchable?: boolean
	searchPlaceholder?: string
	items: ItemProp[]
	filter_key: string
}

const US_MARKET_CAP_CATEGORIES = ["mega", "large", "mid", "small", "micro", "nano"]
const IN_MARKET_CAP_CATEGORIES = ["large", "mid", "small", "micro", "nano"]

const FILTER_MENU_OPTIONS_US: FilterMenuOption[] = [
	{
		key: "company_id",
		title: "Companies",
		searchable: true,
		searchPlaceholder: "Find companies...",
		items: usCompanies.map(
			(item: { company_name: string; company_id: string }) => ({
				id: item.company_id,
				name: item.company_name,
			})
		),
		filter_key: "",
	},
	{
		key: "sector",
		title: "Sectors",
		searchable: true,
		searchPlaceholder: "Find sectors...",
		items: usSectors.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "industry",
		title: "Industries",
		searchable: true,
		searchPlaceholder: "Find industries...",
		items: usIndustries.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "marketcap_category",
		title: "Market Cap",
		searchable: true,
		searchPlaceholder: "Find market cap...",
		items: US_MARKET_CAP_CATEGORIES.map(item => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "year",
		title: "Years",
		searchable: false,
		searchPlaceholder: "Find years...",
		items: SEARCH_YEARS.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "quarter",
		title: "Quarters",
		searchable: true,
		searchPlaceholder: "Find quarters...",
		items: SEARCH_QUARTERS.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	}
]

const FILTER_MENU_OPTIONS_IN: FilterMenuOption[] = [
	{
		key: "company_id",
		title: "Companies",
		searchable: true,
		searchPlaceholder: "Find companies...",
		items: inCompanies.map(
			(item: { company_name: string; company_id: string }) => ({
				id: item.company_id,
				name: item.company_name,
			})
		),
		filter_key: "",
	},
	{
		key: "sector",
		title: "Sectors",
		searchable: true,
		searchPlaceholder: "Find sectors...",
		items: inSectors.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "industry",
		title: "Industries",
		searchable: true,
		searchPlaceholder: "Find industries...",
		items: inIndustries.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "marketcap_category",
		title: "Market Cap",
		searchable: true,
		searchPlaceholder: "Find market cap...",
		items: IN_MARKET_CAP_CATEGORIES.map(item => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "year",
		title: "Years",
		searchable: true,
		searchPlaceholder: "Find years...",
		items: SEARCH_YEARS.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	},
	{
		key: "quarter",
		title: "Quarters",
		searchable: true,
		searchPlaceholder: "Find quarters...",
		items: SEARCH_QUARTERS.map((item: string) => ({
			id: item,
			name: item,
		})),
		filter_key: "",
	}
]

const FOCUS_FILTERS_US: FocusItems[] = [
	{
		slug: "all",
		title: "All",
		values: ["earnings-transcript", "10-K"],
		description: "Search in both documents",
	},
	{
		slug: "earnings-transcript",
		title: "Earnings Transcripts",
		values: ["earnings-transcript"],
		description: "Search in Earnings Transcripts",
	},
	{
		slug: "10-K",
		title: "10-K filings",
		values: ["10-K"],
		description: "Search in 10-K filings",
	},
]
const FOCUS_FILTERS_IN: FocusItems[] = [
	{
		slug: "all",
		title: "All",
		values: ["earnings-transcript", "annual-report"],
		description: "Search in both documents",
	},
	{
		slug: "earnings-transcript",
		title: "Earnings Transcripts",
		values: ["earnings-transcript"],
		description: "Search in Earnings Transcripts",
	},
	{
		slug: "annual-report",
		title: "Annual Reports",
		values: ["annual-report"],
		description: "Search in annual reports",
	},
]

// TODO: While the types (focusFilters) are defined here, the actual data sent to the backend depends on the focusFilters state. These constants should ideally be used to ensure the correct focus filters are sent to the backend. Fix this issue.
const AI_ASSIST_QUESTIONS_US: AiAssistQuestion[] = [
	{
		id: "1",
		question: "What is nvidia's moat in AI chips?",
		values: ["earnings-transcript", "10-K"],
		type: "Earnings Transcripts & 10-k Filings",
	},
	{
		id: "2",
		question: "Are there any companies into lithium battery recycling?",
		values: ["earnings-transcript", "10-K"],
		type: "Earnings Transcripts & 10-k Filings",
	},
	{
		id: "3",
		question:
			"Is there any dicussion around climate change in any of the calls?",
		values: ["earnings-transcript", "10-K"],
		type: "Earnings Transcripts & 10-k Filings",
	},
]
const AI_ASSIST_QUESTIONS_IN: AiAssistQuestion[] = [
	// Default focus filter for IN is set to EARNINGS_TRANSCRIPT only to optimize query performance.
	// Both document types are still displayed to the user.
	{
		id: "1",
		question: "Are there any suppliers to Vandhe Bharath trains?",
		values: ["earnings-transcript"],
		type: "Earnings Transcripts & Annual Reports",
	},
	{
		id: "2",
		question: "Is there any discussion around ChatGPT in any of the calls?",
		values: ["earnings-transcript"],
		type: "Earnings Transcripts & Annual Reports",
	},
	{
		id: "3",
		question:
			"How does revenue from movie tickets compare to sale of food for PVR? ",
		values: ["earnings-transcript"],
		type: "Earnings Transcripts & Annual Reports",
	},
]

export const PRICING_FEATURES_IN_FREE = [
	"10 AI-Summaries/month for Earnings Calls",
	"10 monthly AI searches: Earnings Calls & Annual Reports",
	"Announcements: Last one day's announcements",
	"Watchlists & Alerts: Up to 5 stocks",
]

export const PRICING_FEATURES_IN_PRO = [
	"Unlimited AI-Summaries for Earnings Calls",
	"Unlimited AI-Search over Earnings Calls and Annual Reports",
	"Announcements-Dashboard: Full Access",
	"Watchlists & Alerts: Unlimited stocks",
]

export const PRICING_FEATURES_US_FREE = [
	"10 AI-Summaries/month for Earnings Calls",
	"10 monthly AI searches: Earnings Calls & 10-Ks",
	"8K-Dashboard: Last one day's 8-Ks",
	"Watchlists & Alerts: Up to 5 stocks",
]

export const PRICING_FEATURES_US_PRO = [
	"Unlimited AI-Summaries for Earnings Calls",
	"Unlimited AI-Search over Earnings Calls and 10-Ks",
	"8K-Dashboard: Full Access",
	"Watchlists & Alerts: Unlimited stocks",
]

const PRICING_CONTENT: PricingContentProp = {
	IN: {
		FREE: {
			title: "Try out core features with limited access",
			description: PRICING_FEATURES_IN_FREE,
			buttonText: "Current Plan",
			plans: [
				{
					amount: 0,
					term: "quarter",
				},
			],
		},
		PRO_UNPAID: {
			title: "Unlock full access to all features",
			description: PRICING_FEATURES_IN_PRO,
			buttonText: "Upgrade to Pro",
			plans: [
				{
					amount: 450,
					term: "quarter",
				},
				{
					amount: 1500,
					term: "year",
				},
			],
		},
		PRO_PAID: {
			title: "Full access to all features",
			description: PRICING_FEATURES_IN_PRO,
			buttonText: "Manage Subscription",
			plans: [
				{
					amount: 1500,
					term: "year",
				},
			],
		},
	},
	US: {
		FREE: {
			title: "Try out core features with limited access",
			description: PRICING_FEATURES_US_FREE,
			buttonText: "Current Plan",
			plans: [
				{
					amount: 0,
					term: "quarter",
				},
			],
		},
		PRO_UNPAID: {
			title: "Unlock full access to all features",
			description: PRICING_FEATURES_US_PRO,
			buttonText: "Upgrade to Pro",
			plans: [
				{
					amount: 10,
					term: "month",
				},
				{
					amount: 100,
					term: "year",
				},
			],
		},
		PRO_PAID: {
			title: "Full access to all features",
			description: PRICING_FEATURES_US_PRO,
			buttonText: "Manage Subscription",
			plans: [
				{
					amount: 10,
					term: "month",
				},
			],
		},
	},
}

const CURRENCY_SYMBOL = {
	IN: "₹",
	US: "$",
}

const CURRENCY_DISPLAY_TEXT = {
	IN: "INR",
	US: "USD",
}

export {
	AI_ASSIST_QUESTIONS_IN,
	AI_ASSIST_QUESTIONS_US,
	FOCUS_FILTERS_IN,
	FOCUS_FILTERS_US,
	ROUTES,
	DOC_TYPES,
	SENTIMENTS,
	ANNOUNCEMENTS_CATEGORIES,
	FILTER_MENU_OPTIONS_IN,
	FILTER_MENU_OPTIONS_US,
	FILING_CONFIG,
	SEARCH_YEARS,
	SEARCH_QUARTERS,
	PRICING_CONTENT,
	CURRENCY_SYMBOL,
	CURRENCY_DISPLAY_TEXT,
}
